import React from 'react'
import { facebookIcon, instagramIcon, twitterIcon, telegramIcon } from '../constant/icon'

const Footer = () => {
    const facebookUrl = process.env.REACT_APP_FACEBOOK_URL;
    const instagramUrl = process.env.REACT_APP_INSTAGRAM_URL;
    const twitterUrl = process.env.REACT_APP_TWITTER_URL;
    const telegramUrl = process.env.REACT_APP_TELEGRAM_URL;

    const handleMenuItemClick = (event) => {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').substr(1);
        const targetElement = document.getElementById(targetId);
        targetElement.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className='bg-black'>
            <div className='flex lg:flex-row flex-col  justify-between py-5 mx-16'>
                <div className='flex justify-center gap-5 text-white text-xl'>
                    <a href={`${facebookUrl}`} target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>
                        <i className={`${facebookIcon}`} />
                    </a>
                    <a href={`${instagramUrl}`} target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>

                        <i className={`${instagramIcon}`} />
                    </a>
                    <a href={`${telegramUrl}`} target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>

                        <i className={`${telegramIcon}`} />
                    </a>
                    <a href={`${twitterUrl}`} target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>
                        <i className={`${twitterIcon}`} />
                    </a>
                </div>

                <div className='flex sm:flex-row flex-col items-center justify-center text-white lg:mt-0  mt-4'>
                    <a href='#about' className='hover:underline' onClick={(e) => handleMenuItemClick(e)}>About Us</a><span className='text-customGreen mx-5 '>#</span>
                    <a href='#how-it-works' className='hover:underline' onClick={(e) => handleMenuItemClick(e)}>How It Works</a><span className='text-customGreen mx-5 '>#</span>
                    <a href='#sports' className='hover:underline' onClick={(e) => handleMenuItemClick(e)}>Sports</a><span className='text-customGreen mx-5 '>#</span>
                    <a href='#world-11-token' className='hover:underline' onClick={(e) => handleMenuItemClick(e)}>World 11 Token</a><span className='text-customGreen mx-5 '>#</span>
                    <a href='#tournaments' className='hover:underline' onClick={(e) => handleMenuItemClick(e)}>Tournaments</a><span className='text-customGreen mx-5 '>#</span>
                    <a href='/privacy-policy' target='_blank' className='hover:underline'>Privacy Policy</a>
                </div>
            </div>
            {/* <div className='border border-customGray' /> */}
            <hr className='border border-customGray' />
            <div className='text-gray-500 py-5 md:mx-16 mx-8 text-center'> © {new Date().getFullYear()} All rights reserved by <a href='#home-section' className='text-customGreen font-medium hover:text-[#42d879]' onClick={(e) => handleMenuItemClick(e)}>World 11</a></div>

        </div>
    )
}

export default Footer
