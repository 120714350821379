import React from 'react';

function Privacypolicy() {
  return (
    <div className="max-w-4xl mx-auto p-4 text-justify" style={{ marginTop: '100px' }}>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-black mb-4">
        We are the flagship brand of W11, offering our services to you, where you can participate in various fantasy sports contests hosted on our platform. Any individual utilizing the platform or any of its features, including participation in contests, will be bound by the terms of this Privacy Policy.
      </p>
      <p className="text-black mb-4">
        All capitalized terms not defined herein shall have the meanings ascribed to them in the Terms and Conditions.
      </p>
      <p className="text-black mb-4">
        We respect the privacy of our users and are committed to protecting it in all respects. To offer an engaging and seamless experience, we provide a range of services through our platform. Kindly refer to the "About Us" section to learn more. Most of our services are accessible for free; however, registration is required to participate in contests. We collect information about users in two ways: (i) information provided by users and (ii) information automatically tracked when users navigate through the platform.
      </p>
      <p className="text-black mb-4">
        Before submitting any information on the platform, please read this Privacy Policy carefully to understand how we collect, use, disclose, and store your data. By using any part of the platform, you consent to the collection, use, and disclosure of your information as outlined in this Privacy Policy. Your use of the platform indicates your acceptance of this Privacy Policy and the practices described herein.
      </p>
      <p className="text-black mb-4">
        While you have the option not to provide certain personal information or to withdraw consent for data collection, please note that doing so may restrict your access to certain features and services. In such cases, W11 reserves the right to limit or deny access to its services.
      </p>
      <p className="text-black mb-4">
        Given the dynamic nature of the digital ecosystem, we may periodically update this Privacy Policy to reflect necessary changes and comply with applicable laws. Any updates will be communicated to you, and changes will be published on this page to keep you informed about the collection, usage, storage, and disclosure of your information.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Purpose and Usage</h2>
      <p className="text-black mb-4">
        To access certain features on the W11 platform, users may be required to provide the following information during registration:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Username</li>
        <li>Email address</li>
        <li>Date of birth</li>
        <li>State</li>
        <li>Government ID (such as Aadhaar card, driving license, or voter ID)</li>
      </ul>
      <p className="text-black mb-4">
        Users can also invite others ("Invited Users") to participate in contests through social media platforms like WhatsApp, Instagram, or Facebook. W11 may use this information to send invitations and facilitate user participation. By inviting others, you confirm that the invited individuals have consented to share their information with W11. Additionally, users can sync their phonebook to invite friends directly from the platform.
      </p>
      <p className="text-black mb-4">
        All collected information is specific to the features and contests users wish to participate in and is used solely to enhance the platform experience.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Information We Collect</h2>
      <p className="text-black mb-4">
        To provide you with the best experience, we collect various types of information necessary for platform functionality, security, and compliance.
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Personal Information:</strong> We collect the following details to meet regulatory requirements and ensure user security:
          <ul className="list-disc list-inside pl-6">
            <li>Full Name</li>
            <li>Email Address</li>
            <li>Phone Number</li>
            <li>Date of Birth</li>
            <li>Government-issued ID (for identity verification and security purposes)</li>
          </ul>
        </li>
        <li>
          <strong>Account Information:</strong> We securely store:
          <ul className="list-disc list-inside pl-6">
            <li>Username</li>
            <li>Password (encrypted for security)</li>
            <li>Security questions and answers</li>
          </ul>
        </li>
        <li>
          <strong>Device and Usage Information:</strong> To optimize platform performance, we automatically collect:
          <ul className="list-disc list-inside pl-6">
            <li>IP Address</li>
            <li>Browser Type</li>
            <li>Device Type (Mobile, Tablet, or Desktop)</li>
            <li>Operating System</li>
            <li>Application Usage Data</li>
            <li>Log Data and Session Information</li>
          </ul>
        </li>
        <li>
          <strong>Transaction Information:</strong> To facilitate seamless transactions, we collect:
          <ul className="list-disc list-inside pl-6">
            <li>Wallet Addresses (for USDT deposits and withdrawals)</li>
            <li>Transaction History</li>
            <li>Token Balances (for in-app credit usage)</li>
          </ul>
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">How We Use Your Information</h2>
      <p className="text-black mb-4">
        We use your information to create and manage your W11 account, providing seamless access to contests and various gaming features. Your data enables secure crypto token transactions while ensuring compliance with applicable laws and regulations. Additionally, we utilize this information to enhance and improve the user experience, making the platform more engaging and efficient. We also send important updates, security alerts, and promotional materials to keep you informed about new features, offers, and essential notifications.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Credit System & Transactions</h2>
      <p className="text-black mb-4">
        W11 enables users to deposit USDT in exchange for in-app credits, with the conversion rate determined by the admin. These credits can be used to participate in contests and access various platform features. For withdrawals, users must submit a request, which will be reviewed and approved by the admin before processing. All transactions are securely handled, ensuring transparency and reliability. Additionally, users are solely responsible for managing their wallets, as W11 does not store or request private keys, maintaining a high level of security and user autonomy.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Data Sharing and Disclosure</h2>
      <p className="text-black mb-4">
        We do not sell, rent, or trade your personal information. However, in certain cases, we may share data to ensure compliance and enhance platform functionality. If required by law, we may disclose information to legal and regulatory authorities to comply with legal processes. Additionally, we collaborate with third-party service providers for essential services such as verification, security, and analytics. In some instances, we may also share data with business partners when integrating third-party applications or services to improve the overall user experience while maintaining strict data protection standards.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Security Measures</h2>
      <p className="text-black mb-4">
        We prioritize data security and implement industry-standard measures to ensure the protection of user information. Sensitive data is encrypted to prevent unauthorized access, safeguarding personal details from potential threats. To maintain platform integrity, we conduct both automated and manual checks to detect and prevent fraudulent activities. Additionally, access to user information is strictly restricted to authorized personnel, ensuring that data remains secure and confidential at all times.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Data Retention</h2>
      <p className="text-black mb-4">
        Your personal information may be retained and may continue to be used until: (i) the relevant purposes for the use of Your personal information described in this Privacy Policy are no longer applicable; and (ii) We are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain Your personal information and the retention of Your personal information is not required for the establishment, exercise or defense of any legal claim.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">User  Account and Data Deletion</h2>
      <p className="text-black mb-4">
        Users can request the deletion of their accounts and personal information by sending an email to the support team at  <span className='text-blue-500'>worldxi80@gmail.com</span> or call <span className='text-blue-500'>+971 522976762</span>. Upon receiving the request, we will make every effort to respond promptly, ensuring a resolution within one month from the date of receiving the request or any additional information required to process it. Users also have the option to request account suspension, which will restrict participation in paid contests while still allowing access to other features of the platform. Alternatively, users may opt for temporary deactivation, which will disable login access but allow for future reactivation along with all associated account data.
      </p>
      <p className="text-black mb-4">
        In cases where users choose to permanently deactivate their accounts, they will lose access to the platform and all associated data. However, account restoration may still be requested within six months from the confirmation of account deletion by contacting the support team. When a deletion request is processed, we ensure that the data is safely and completely removed from our servers or retained only in anonymized form. As part of our commitment to data protection, there may be a slight delay between the request and the final deletion from our active and backup systems to prevent accidental or malicious loss of information.
      </p>
      

      <h2 className="text-2xl font-bold mt-6 mb-2">Your Rights and Choices</h2>
      <p className="text-black mb-4">
        As a W11 user, you have the right to access, update, or delete your personal information. You can also opt out of receiving promotional communications at any time.
      </p>
      <p className="text-black mb-4">
        Additionally, you may request details on how your data is processed and stored, ensuring transparency and control over your information. If applicable, you have the right to withdraw consent for data processing.
      </p>
      <p className="text-black mb-4">
        To exercise any of these rights, please contact our support team at <a className="text-blue-500">worldxi80@gmail.com</a>.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Cookies and Tracking Technologies</h2>
      <p className="text-black mb-4">
        W11 uses cookies and tracking technologies to improve platform performance and enhance the overall user experience. These technologies help analyze gaming trends and user preferences, allowing us to provide personalized content and promotions tailored to individual interests.
      </p>
      <p className="text-black mb-4">
        Users have the option to manage their cookie preferences through their browser settings, giving them control over how their data is collected and used.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Updates to This Policy</h2>
      <p className="text-black mb-4">
        We may update this Privacy Policy from time to time. Changes will be reflected in the "Effective Date" at the top of this page. Continued use of W11 after updates signifies acceptance of the revised policy.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Contact Us</h2>
      <p className="text-black mb-4">
        Any questions or clarifications with respect to this Privacy Policy or any complaints, comments, concerns or feedback can be sent to Us by clicking on Help and Support or by normal/physical mail addressed to: <a className="text-blue-500">worldxi80@gmail.com</a>.
      </p>
      <p className="text-black mb-4">
        Attn: world11 Team,<br />
        Dubai city 25314<br />
        UAE
      </p>
    </div>
  );
}

export default Privacypolicy;