import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const IosApp = () => {
  const url = process.env.REACT_APP_IOS_APP_URL;
  const navigate = useNavigate();

  const checkURL = () => {
    if (url) {
      window.location.href = url;
      setTimeout(() => {
        window.close();
      }, 2000)
    } else {
      navigate('/')
    }
  }

  useEffect(() => {
    checkURL();
    // eslint-disable-next-line
  }, [])

  return (
    <></>
  )
}

export default IosApp