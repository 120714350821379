import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';


const AndroidApp = () => {
    const url = process.env.REACT_APP_ANDROID_APP_URL;
    const navigate = useNavigate();

    const checkURL = () => {
        if (url) {
            window.location.href = url;
            setTimeout(() => {
                window.close();
              }, 2000)
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        checkURL();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {/* <div className='container flex items-center justify-center min-h-screen'>
                <h1 className='md:text-3xl text-xl px-4 text-center text-black'>World 11 APK is currently downloading. Please check your download history.</h1>
            </div> */}
        </>
    )
}

export default AndroidApp