import React, { useContext, useEffect, useState } from 'react';
import { NavbarContext, navItems } from '../constant/config';

const Navbar = () => {
    const { isOpen, setIsOpen } = useContext(NavbarContext);
    const [isActive, setIsActive] = useState(1);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // Function to handle scroll event
    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY) {
            // Scrolling down
            setIsNavbarVisible(false);
        } else {
            // Scrolling up
            setIsNavbarVisible(true);
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        // Add scroll event listener when component mounts
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        //eslint-disable-next-line
    }, [lastScrollY]);


    useEffect(() => {
        // if (!isMobileView()) return;
        const handleScroll = () => {
            let currentId = 1;
            navItems.forEach((item) => {
                const section = document.getElementById(item.href.substr(1));
                if (section) {
                    const { top, bottom } = section.getBoundingClientRect();
                    if (top <= 50 && bottom >= 50) {
                        currentId = item.id;
                    }
                }
            });
            setIsActive(currentId);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, [navItems]);


    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup when component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
        // eslint-disable-next-line
    }, [isOpen]);


    return (
        <div>
            <nav className={`${window.pageYOffset < window.innerHeight ? "absolute" : 'fixed'} bg-white w-full z-50  top-0 ${isNavbarVisible ? "translate-y-0" : "-translate-y-full"
                } duration-300`}>
                <div className="mx-auto">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <a href="/" className="text-3xl font-normal text-white flex items-center  px-4 py-2 sm:px-6 lg:px-8">
                                <img src="/assets/images/w11.png" alt="logo" className='w-16' />
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
