// import React from 'react';
// import { Fade, Flip } from 'react-awesome-reveal'
// import { Link } from 'react-router-dom';
// import { QRCodeCanvas } from 'qrcode.react';

// const Download = () => {
//     return (
//         <div className='relative mt-10'>
//             <div className="bg-[url('/public/assets/images/partnership-bg.png')] bg-no-repeat h-full bg-cover">
//                 <div className='lg:mx-9 md:mx-8 sm:mx-4 mx-2'>
//                     <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 grid-cols-1 h-full'>
//                         <div className='flex flex-col lg:py-20 sm:py-10 py-6 px-4'>
//                             <Fade left>
//                                 <h1 className='lg:text-6xl md:text-5xl sm:text-4xl text-4xl font-bold text-black lg:text-left text-center'>
//                                     Download World11 now for Free
//                                 </h1>
//                                 <p className='lg:text-2xl md:text-2xl sm:text-lg text-base lg:mt-6 mt-4 text-black lg:text-left text-center'>
//                                     Introducing World 11 - the next level in fantasy sports! Download our app now to create your dream team and join millions of players in thrilling competitions for amazing rewards.
//                                 </p>
//                             </Fade>

//                             <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col my-10 gap-4">
//                                 <Flip bottom>
//                                     <Link to='/ios' target='_blank' className='bg-white text-sm uppercase tracking-wide py-4 px-6 text-black rounded-2xl font-bold hover:scale-95 transition-transform duration-300 flex items-center justify-center'>
//                                         <i className="fa-brands fa-apple text-3xl mr-3"></i>
//                                         Download For IOS
//                                     </Link>
//                                 </Flip>
//                                 <Flip bottom>
//                                     <Link to='/android' target='_blank' className='bg-white text-sm uppercase tracking-wide py-4 px-6 text-black rounded-2xl font-bold hover:scale-95 transition-transform duration-300 flex items-center justify-center'>
//                                         <i className="fa-brands fa-google-play text-3xl mr-3"></i>
//                                         Download For Android
//                                     </Link>
//                                 </Flip>
//                             </div>

//                             <div className='lg:hidden flex justify-center my-8'>
//                                 <div className='bg-white p-4 rounded-2xl shadow-xl'>
//                                     <QRCodeCanvas 
//                                         value={`${window.location.origin}/downloads`}
//                                         size={160}
//                                     />
//                                 </div>
//                             </div>
//                         </div>

//                         <div className='relative lg:py-20 md:py-10 py-0'>
//                             <div className='flex flex-col items-center lg:h-[650px] justify-center'>
//                                 <img 
//                                     src="/public/assets/images/mobile-downloads.png" 
//                                     alt="Mobile app showcase" 
//                                     className='lg:w-80 md:w-72 sm:w-64 w-56 mx-auto lg:-rotate-12 transform hover:rotate-0 transition-transform duration-500' 
//                                 />

//                                 <div className='hidden lg:block mt-12'>
//                                     <div className='bg-white p-4 rounded-2xl shadow-xl rotate-12 transform hover:rotate-0 transition-transform duration-500'>
//                                         <QRCodeCanvas 
//                                             value={`${window.location.origin}/downloads`}
//                                             size={180}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Download;

import React from 'react';
import { Fade, Flip } from 'react-awesome-reveal';
import { QRCodeCanvas } from 'qrcode.react';

const Download = () => {
    const androidUrl = process.env.REACT_APP_ANDROID_APP_URL;
    return (
        <>
            <div className='relative mt-24'>
                <div className="bg-[url('/public/assets/images/partnership-bg.png')] bg-no-repeat h-full bg-cover">
                    <div className='lg:mx-9 md:mx-8 sm:mx-4 mx-2 lg:max-h-[50rem] md:max-h-[70rem] max-h-[100rem] overflow-hidden'>
                        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 grid-cols-1 h-full">
                            <div className='flex flex-col lg:py-20 sm:py-10 py-0 px-4 mt-10'>
                                <Fade left>
                                    <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-4xl font-bold text-black lg:text-left md:text-left sm:text-center'>Download World 11 Fantasy App</h1>
                                </Fade>
                                <div className='my-3'>
                                    <Fade left>
                                        <p className='lg:text-lg md:text-lg sm:text-lg text-lg lg:mt-6 mt-1 text-black text-justify'>Introducing World 11 - the next level in fantasy sports! Download our app now to create your dream team and join millions of players in thrilling competitions for amazing rewards. Don't miss out on the excitement, join World 11 today!</p>
                                    </Fade>
                                </div>
                                <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col my-10 gap-4 md:mt-6 lg:justify-normal md:justify-center">
                                    {/* <Flip bottom> */}
                                        <a
                                            href="/android"
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            className='bg-black text-sm uppercase tracking-wide py-4 px-6 text-white rounded-2xl font-bold hover:scale-95 transition-transform duration-300 flex items-center justify-center'
                                        >
                                            <i className="fa-brands fa-google-play text-3xl mr-3"></i>
                                            Download For Android
                                        </a>
                                    {/* </Flip> */}
                                    {/* <Flip bottom> */}
                                        <a
                                            href="/ios"
                                            target='_blank'
                                            rel="noopener noreferrer"
                                            className='bg-black text-sm uppercase tracking-wide py-4 px-6 text-white rounded-2xl font-bold hover:scale-95 transition-transform duration-300 flex items-center justify-center'
                                        >
                                            <i className="fa-brands fa-apple text-3xl mr-3"></i>
                                            Download For IOS
                                        </a>
                                    {/* </Flip> */}
                                </div>
                                <div className="justify-normal items-center hidden lg:flex">
                                    <QRCodeCanvas value={`${window.location.origin}/downloads`} className='p-3 bg-white rounded-2xl' size={150} />
                                    <span className='ml-2 pl-2 text-base'>Scan the QR Code to download<br/>the World 11 Fantasy App</span>
                                </div>
                            </div>

                            <div className='relative flex justify-center items-center lg:mt-10 md:mt-0 sm:mt-10 mt-10'>
                                <div className='flex justify-center'>
                                    <img src="/assets/images/mobile-downloads.png" alt="Mobile Downloads" className='lg:w-72 md:w-60 w-80 md:mt-0 mb-10 mx-auto' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Download;