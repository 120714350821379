// import { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Change Switch to Routes
// import './App.css';
// import About from './components/About';
// import Blog from './components/Blog';
// import Footer from './components/Footer';
// import Home from './components/Home';
// import HowItWork from './components/HowItWork';
// import Navbar from './components/Navbar';
// import Partnership from './components/Partnership';
// import ScrollButton from './components/ScrollButton';
// import Sports from './components/Sports';
// import Tournaments from './components/Tournaments';
// import Wolrd11Token from './components/Wolrd11Token';
// import { NavbarContext } from './constant/config';
// import Privacypolicy from './components/Privacypolicy';
// import Download from './components/Download';
// import IosApp from './components/installApp/IosApp';
// import AndroidApp from './components/installApp/AndroidApp';
// import Downloads from './components/installApp/Downloads';

// function App() {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <NavbarContext.Provider value={{ isOpen, setIsOpen }}>
//       <Router>
//         <div>
//           <Navbar />
//           <div>
//             <Routes> {/* Use Routes instead of Switch */}
//               <Route path="/" element={
//                 <>
//                   <section className={`section`} id="home-section">
//                     <Home />
//                   </section>
//                   <section className={`section`} id="about">
//                     <About />
//                   </section>
//                   <section className={`section`} id="how-it-works">
//                     <HowItWork />
//                   </section>
//                   <section className={`section`} id="partnership">
//                     <Partnership />
//                   </section>
//                   <section className={`section`} id="sports">
//                     <Sports />
//                   </section>
//                   <section className={`section`} id="world-11-token">
//                     <Wolrd11Token />
//                   </section>
//                   <section className={`section`} id="tournaments">
//                     <Tournaments />
//                   </section>
//                   <section className={`section`} id="blog-news">
//                     <Blog />
//                   </section>
//                   <section className={`section`} id="download">
//                     <Download />
//                   </section>
//                   <section className={`section`} id="contact-us">
//                     <Footer />
//                   </section>
//                 </>
//               } />
//               <Route path="/ios" element={<IosApp />} />
//               <Route path="/android" element={<AndroidApp />} />
//               <Route path="/downloads" element={<Downloads />} />
//               <Route path="/privacy-policy" element={<Privacypolicy />} />
//             </Routes>
//           </div>
//           <ScrollButton />
//         </div>
//       </Router>
//     </NavbarContext.Provider>
//   );
// }

// export default App;



import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Blog from './components/Blog';
import Footer from './components/Footer';
import Home from './components/Home';
import HowItWork from './components/HowItWork';
import Navbar from './components/Navbar';
import NavbarForPolicy from './components/NavbarForPolicy';
import Partnership from './components/Partnership';
import ScrollButton from './components/ScrollButton';
import Sports from './components/Sports';
import Tournaments from './components/Tournaments';
import Wolrd11Token from './components/Wolrd11Token';
import { NavbarContext } from './constant/config';
import Privacypolicy from './components/Privacypolicy';
import Download from './components/Download';
import IosApp from './components/installApp/IosApp';
import AndroidApp from './components/installApp/AndroidApp';
import Downloads from './components/installApp/Downloads';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); 
  const isPolicyRoute = ['/ios', '/android', '/downloads', '/privacy-policy'].includes(location.pathname);

  return (
    <NavbarContext.Provider value={{ isOpen, setIsOpen }}>
      <div>
        {isPolicyRoute ? <NavbarForPolicy /> : <Navbar />}
        <div>
          <Routes>
            <Route path="/" element={
              <>
                <section className={`section`} id="home-section">
                  <Home />
                </section>
                <section className={`section`} id="about">
                  <About />
                </section>
                <section className={`section`} id="how-it-works">
                  <HowItWork />
                </section>
                <section className={`section`} id="partnership">
                  <Partnership />
                </section>
                <section className={`section`} id="sports">
                  <Sports />
                </section>
                <section className={`section`} id="world-11-token">
                  <Wolrd11Token />
                </section>
                <section className={`section`} id="tournaments">
                  <Tournaments />
                </section>
                <section className={`section`} id="blog-news">
                  <Blog />
                </section>
                <section className={`section`} id="download">
                  <Download />
                </section>
                <section className={`section`} id="contact-us">
                  <Footer />
                </section>
              </>
            } />
            <Route path="/ios" element={<IosApp />} />
            <Route path="/android" element={<AndroidApp />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <ScrollButton />
      </div>
    </NavbarContext.Provider>
  );
}

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;